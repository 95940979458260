@import "./vars.scss";

.modal {
  & .modal-dialog {
    background-color: white;
    margin: 0;
    padding: 0;
    min-width: 100vw !important;
    max-width: 100vw !important;
    height: 100vh !important;
    border: none;
    display: flex;
    & .modal-content {
      background-color: transparent;
      border: none;
      margin: auto;
    }
    &.confirm-request-locker-modal{
      background-color: $green;
      & .modal-content {
        & .modal-header {
          border: none;
          width: 100%;
          padding: 0;
          color: $dark_blue;
          margin-bottom: 3vh;
          & .modal-title {
            padding: 0;
            margin: 0 auto;
            font-weight: 600;
            font-size: 2vw;
            letter-spacing: $letterSpacing;
          }
        }
        & .modal-body {
          display: flex;
          justify-content: center;
          padding: 0;
          margin: 0;
          position: relative;
          & svg{
            width: 25vw;
            height: 25vw;
          }
          & p {
            position: absolute;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 5vw;
            font-weight: 600;
            color: $dark_blue;
          }
        }
    }
  }
  &.confirm-request-locker-modal-free{
    background-color: $blue;
    & .modal-content {
      & .modal-header {
        border: none;
        width: 100%;
        padding: 0;
        color: $dark_blue;
        margin-bottom: 3vh;
        & .modal-title {
          padding: 0;
          margin: 0 auto;
          font-weight: 600;
          font-size: 2vw;
          letter-spacing: $letterSpacing;
        }
      }
      & .modal-body {
        display: flex;
        justify-content: center;
        padding: 0;
        margin: 0;
        position: relative;
        & svg{
          width: 25vw;
          height: 25vw;
        }
        & p {
          position: absolute;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 5vw;
          font-weight: 600;
          color: $dark_blue;
        }
      }
      & button {
        background-color: $red;
        outline: none;
        border: none;
        width: 25vw;
        height: 5vw;
        margin: auto;
        border-radius: 0.8vw;
        margin-top: 5vh;
        & span {
          color: white;
          font-size: 2vw;
          letter-spacing: $letterSpacing;
        }
      }
  }
}
    
    
    
    &.assigning-locker-modal {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $red;
      & .modal-content {
        width: fit-content;
        height: fit-content;
        & .modal-header {
          border: none;
          width: 100%;
          padding: 0;
          color: $white;
          & .modal-title {
            padding: 0;
            margin: 0 auto;
            font-weight: 600;
            font-size: 2.3vw;
            letter-spacing: $letterSpacing;
          }
        }
        & .modal-body {
          padding: 0;
          margin: 0;
          & img {
            width: 50vw;
            padding: 0;
            margin: 0;
          }
        }
        & span {
          margin: 0 auto 0 auto;
          padding: 0;
          text-align: center;
          width: 100%;
          color: $white;
          font-size: 2vw;
          letter-spacing: $letterSpacing;
        }
      }
    }
    &.wrong-location-error-modal {
      background-color: $orange;
      & .modal-content {
        & .modal-header {
          justify-content: center;
          text-align: center;
          border: none;
          margin-bottom: 3vh;
          & h5 {
            color: $dark_blue;
            font-weight: bold;
            font-size: 2.2vw;
          }
        }
        & .modal-body {
          display: flex;
          justify-content: center;
          padding: 0;
          margin: 0;
          position: relative;
          & svg {
            width: 25vw;
            height: 25vw;
          }
          & p {
            position: absolute;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 5vw;
            font-weight: 600;
            color: $dark_blue;
          }
        }
      }
    }
    &.confirm-return-locker-modal,
    &.releasing-error-modal,
    &.no-ids-error-modal,
    &.assigning-locker-error-modal,
    &.processing-request-modal {
      & .modal-body {
        display: flex;
        justify-content: center;
        align-items: center;
        & .wrapper {
          text-align: center;
          width: fit-content;
          & svg {
            margin: auto;
            width: 15vw;
            height: auto;
          }
          & .text-div {
            margin-top: 8vh;
            text-align: center;
            & p {
              font-weight: bold;
              font-size: 2.3vw;
            }
          }
          & .process-loading {
            width: 15vw;
            height: auto;
          }
        }
      }
    }
  }
}
