@import "./components/header.scss";
@import "./components/footer.scss";
@import "./components/action-button.scss";
@import "./components/my-button.scss";
@import "./components/icon-button.scss";
@import "./components/occupation-stats.scss";
@import "./components/locker-list.scss";
@import "./components/countdown-timer.scss";

@import "./views/return-locker.scss";
@import "./views/occupation.scss";
@import "./views/home.scss";
@import "./views/processing.scss";
@import "./views/help.scss";
@import "./vars.scss";
@import "./views/modals.scss";

@import "./vars.scss";

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&display=swap");

body {
  font-family: "Open Sans", sans-serif;
  overflow: hidden;
  touch-action: none;
  max-height: 100vh;
  min-height: 100vh;
}

#root {
  max-height: 100vh;
  min-height: 100vh;
  position: relative;
}
::-webkit-scrollbar {
  width: 20px;
}
::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: rgba(12, 177, 222, 0.3);
}
::-webkit-scrollbar-thumb {
  background-color: #00a2ce;
  border-radius: 5px;
}

.my-shadow {
  -webkit-box-shadow: 0px 0px 9px 2px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 0px 9px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 9px 2px rgba(0, 0, 0, 0.3);
}

.png-shadow {
  -webkit-filter: drop-shadow(3px 2px 2px rgba(0, 0, 0, 0.3));
  filter: drop-shadow(3px 2px 2px rgba(0, 0, 0, 0.3));
}

.available {
  background: linear-gradient(to bottom, #40cf9f 0%, #45deab 100%);
}
.occupied {
  background: linear-gradient(to bottom, #c7715b 0%, #e67e6a 100%);
}
.disabled {
  background: linear-gradient(to bottom, #414345 0%, #232526 100%);
}
.cleaning {
  background: linear-gradient(to bottom, #ffe27a 0%, #e8d964 100%);
}
.locker-status-0 {
  background: linear-gradient(to bottom, #17e8de 0%, #02b5ff 100%);
}
.locker-status-1 {
  background: linear-gradient(to bottom, #e52d27 0%, #b31217 100%);
}
.card-title {
  font-size: 1.5em;
  color: #2e2e2e;
}
.danger {
  background-color: #c91616;
  color: #fff;
}
.primary {
  background-color: #00a2ce;
  color: #fff;
}

.background {
  margin: 0;
  padding: 0;
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  & .info {
    height: fit-content;
    width: fit-content;
    margin: 0;
  padding: 0;
    
    & .wrapper {
      width: 70vw;
      padding: 0;
      margin: auto;
      & img {
        width: 100%;
        height: 100%;
      }
    }
    & span {
      margin: 0 auto 0 auto;
      padding: 0;
      text-align: center;
      width: 100%;
      color: $dark_blue;
      font-size: 1.8vw;
    }
  }
}
