.help-modal {
  & .modal-header {
    border: none;
    color: $dark_blue;
    margin-bottom: 5vh;
    & .modal-title {
      padding: 0 2vw;
      font-weight: 600;
      font-size: 2.5vw;
      letter-spacing: $letterSpacing;
    }
  }
  & .modal-row {
    margin: 0;
    & .col-6 {
      padding: 0 2vw;
      color: $dark_blue;
      font-size: 1.6vw;
      letter-spacing: $letterSpacing;
      & .help-text-big {
        color: $purple;
        font-size: 1.9vw;
        font-weight: bold;
        margin-bottom: 3.5vh;
      }
      & .help-text {
        & span {
          font-weight: bold;
          color: $purple;
        }
      }
      & .row {
        margin: 0;
      }
    }
  }
}
