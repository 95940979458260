@import "./../vars.scss";


.header-container{
  margin: 0;
  padding: 0;
  width: 100%;
  height: fit-content;
    & .first-col{
      padding: 1vh 0;
      min-height: 100%;
      & svg{
        margin-left: 1.5vw;
        width: 10vw;
        height: 100%;
      }
    }
    & .mid-col{
      padding: 0;
      min-height: 100%;
      padding: 2vh 0;
      & p{
        padding: 0;
        margin: 0;
        width: 100%;
        display:flex;
        font-size: 2.5vw;
        height: fit-content;
        justify-content: center;
        color: $dark_blue
      }
      & span{
        padding: 0;
        margin: 0;
        width: 100%;
        display:flex;
        height: fit-content;
        font-size: 2vw;
        justify-content: center;
        color: $dark_blue
      }
    }
    & .last-col{
      padding: 0;
      display: flex;
      justify-content: flex-end;
      min-height: 100%;
      padding: 2vh 0;
      & .wrapper{
        margin-right: 1.5vw;
        & p{
          padding: 0;
          margin: 0;
          width: fit-content;
          display:flex;
          font-size: 2vw;
          
          height: fit-content;
          color: $dark_blue
        }
        & span{
          padding: 0;
          margin:0 0 0 auto;
          width: fit-content;
          display:flex;
          height: fit-content;
          font-size: 2vw;
          font-weight: 600;
          color: $dark_blue
        }
      } 
    }
  }