#countdown-timer {
  position: absolute;
  right: 2vw;
  top: 2vw;
  z-index: 1;
  text-align: center;
  width: 7vw;
 height: 7vw;
 display: flex;
 align-items: center;
 justify-content: center;
 font-size: 2vw;
 color: $dark_blue;
 & svg{
   & div{
    color: $red;
   }
  & path{
    stroke-width: 0.4vw ;
  }
 }
}