.icon-button-wrapper {
    background-color: $purple;
    padding: 0;
    border-radius: 0.4vw;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    & svg {
        margin: 0.5vw 1vw;
        padding: 0.4vw;
        & path {
            height: 100%;
            width: 100%;
        }
    }
}
