@import "./vars.scss";

.footer-container {
  height: 10vh !important;
  height: fit-content;
  padding: 0;
  margin: 0;
  bottom: 2vh;
  position: absolute;
  width: 100vw;
  & .default-footer-container {
    width: 100%;
    height: 100%;
    padding: 0 1vw;
    margin: 0;
    & .main-row {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      margin: auto 0;
      & .lotation-container {
        color: $dark_blue;
        width: 30%;
        padding: 0;
        font-size: 1.5vw;
        & span {
          font-weight: bold;
          margin-left: 1.2vw;
        }

        & .progress {
          margin-top: 1.5vh;
          width: 75%;
          background-color: $light_grey;
          & .progress-bar {
            background-color: $purple;
          }
        }
      }
    }
    & .button-container {
      width: fit-content;
      height: fit-content;
      margin: auto 0 0 auto;
      & svg{
        width: 1.5vw;
        height: 2vw;
      }
    }
  }
}
