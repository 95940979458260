$purple: #ad32c6;
$dark_blue: #00404f;
$light_grey: #eeeeee;
$red: #e73333;
$green: #00ffab;
$orange:#ffc234;
$blue:#00ccff;
$white: #ffffff;

$letterSpacing: 0.1vw;


:export {
        dark_blue: $dark_blue;
        transparent: rgba(white, 0);
        white: $white;
}